<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Unicons-icons component
 */
export default {
  page: {
    title: "Unicons",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Unicons",
      items: [
        {
          text: "Icons",
        },
        {
          text: "Unicons",
          active: true,
        },
      ],
    };
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row icon-demo-content">
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-0-plus"></i> uil-0-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-10-plus"></i> uil-10-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-12-plus"></i> uil-12-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-13-plus"></i> uil-13-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-16-plus"></i> uil-16-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-17-plus"></i> uil-17-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-18-plus"></i> uil-18-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-21-plus"></i> uil-21-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-3-plus"></i> uil-3-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-500px"></i> uil-500px
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-6-plus"></i> uil-6-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-abacus"></i> uil-abacus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-accessible-icon-alt"></i> uil-accessible-icon-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adjust-alt"></i> uil-adjust-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adjust-circle"></i> uil-adjust-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adjust-half"></i> uil-adjust-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adjust"></i> uil-adjust
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adobe-alt"></i> uil-adobe-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-adobe"></i> uil-adobe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-airplay"></i> uil-airplay
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-alt"></i> uil-align-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-center-alt"></i> uil-align-center-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-center-h"></i> uil-align-center-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-center-justify"></i>
                uil-align-center-justify
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-center-v"></i> uil-align-center-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-justify"></i> uil-align-justify
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-center"></i> uil-align-center
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-left-justify"></i> uil-align-left-justify
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-left"></i> uil-align-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-letter-right"></i> uil-align-letter-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align-right-justify"></i> uil-align-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-align"></i> uil-align
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-amazon"></i> uil-amazon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ambulance"></i> uil-ambulance
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-analysis"></i> uil-analysis
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-analytics"></i> uil-analytics
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-anchor"></i> uil-anchor
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-android-alt"></i> uil-android-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-android-phone-slash"></i> uil-android-phone-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-android"></i> uil-android
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-double-down"></i> uil-angle-double-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-double-left"></i> uil-angle-double-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-double-right"></i> uil-angle-double-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-double-up"></i> uil-angle-double-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-down"></i> uil-angle-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-left"></i> uil-angle-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-right-b"></i> uil-angle-right-b
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-right"></i> uil-angle-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-angle-up"></i> uil-angry
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ankh"></i> uil-ankh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-annoyed-alt"></i> uil-annoyed-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-annoyed"></i> uil-annoyed
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-apple-alt"></i> uil-apple-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-apple"></i> uil-apple
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-apps"></i> uil-apps
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-archive-alt"></i> uil-archive
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-archway"></i> uil-archway
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-break"></i> uil-arrow-break
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-circle-down"></i> uil-arrow-circle-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-circle-left"></i> uil-arrow-circle-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-circle-right"></i> uil-arrow-circle-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-circle-up"></i> uil-arrow-circle-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-compress-h"></i> uil-arrow-compress-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down-left"></i> uil-arrow-down-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down-right"></i> uil-arrow-down-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-from-right"></i> uil-arrow-from-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-from-top"></i> uil-arrow-from-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-growth"></i> uil-arrow-growth
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-left"></i> uil-arrow-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-random"></i> uil-arrow-random
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-resize-diagonal"></i>
                uil-arrow-resize-diagonal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-right"></i> uil-arrow-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-to-bottom"></i> uil-arrow-to-bottom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-to-right"></i> uil-arrow-to-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-up-left"></i> uil-arrow-up-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-up-right"></i> uil-arrow-up-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-up"></i> uil-arrow-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow"></i> uil-arrow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-h-alt"></i> uil-arrows-h-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-h"></i> uil-arrows-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-left-down"></i> uil-arrows-left-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-maximize"></i> uil-arrows-maximize
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-merge"></i> uil-arrows-merge
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-resize-h"></i> uil-arrows-resize-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-resize-v"></i> uil-arrows-resize-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-resize"></i> uil-arrows-resize
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-right-down"></i> uil-arrows-right-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-shrink-h"></i> uil-arrows-shrink-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-shrink-v"></i> uil-arrows-shrink-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-v-alt"></i> uil-arrows-v-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrows-v"></i> uil-arrows-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-assistive-listening-systems"></i>
                uil-assistive-listening-systems
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-asterisk"></i> uil-asterisk
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-at"></i> uil-at
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-at"></i> uil-at
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-atom"></i> uil-atom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-auto-flash"></i> uil-auto-flash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-award-alt"></i> uil-award-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-award"></i> uil-award
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-baby-carriage"></i> uil-baby-carriage
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-backpack"></i> uil-backpack
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-backspace"></i> uil-backspace
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-backward"></i> uil-backward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bag-alt"></i> uil-bag-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bag-slash"></i> uil-bag-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bag"></i> uil-bag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-balance-scale"></i> uil-balance-scale
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ban"></i> uil-ban
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bars"></i> uil-bars
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-baseball-ball"></i> uil-baseball-ball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-basketball-hoop"></i> uil-basketball-hoop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-basketball"></i> uil-basketball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bath"></i> uil-bath
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-battery-bolt"></i> uil-battery-bolt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-battery-empty"></i> uil-battery-empty
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bed-double"></i> uil-bed-double
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bed"></i> uil-bed
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-behance-alt"></i> uil-behance-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-behance"></i> uil-behance
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bell-school"></i> uil-bell-school
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bell-slash"></i> uil-bell-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bell"></i> uil-bell
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bill"></i> uil-bill
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bitcoin-alt"></i> uil-bitcoin-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bitcoin-circle"></i> uil-bitcoin-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bitcoin"></i> uil-bitcoin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-black-berry"></i> uil-black-berry
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-blogger-alt"></i> uil-blogger-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-blogger"></i> uil-blogger
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bluetooth-b"></i> uil-bluetooth-b
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bold"></i> uil-bold
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bolt-alt"></i> uil-bolt-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bolt-slash"></i> uil-bolt-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bolt"></i> uil-bolt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-book-alt"></i> uil-book-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-book-medical"></i> uil-book-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-book-open"></i> uil-book-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-book-reader"></i> uil-book-reader
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-book"></i> uil-book
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bookmark-full"></i> uil-bookmark-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bookmark"></i> uil-bookmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-books"></i> uil-books
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-boombox"></i> uil-boombox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-alt"></i> uil-border-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-bottom"></i> uil-border-bottom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-clear"></i> uil-border-clear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-horizontal"></i> uil-border-horizontal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-inner"></i> uil-border-inner
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-left"></i> uil-border-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-out"></i> uil-border-out
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-right"></i> uil-border-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-top"></i> uil-border-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-border-vertical"></i> uil-border-vertical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bowling-ball"></i> uil-bowling-ball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-box"></i> uil-box
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-briefcase"></i> uil-briefcase
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-brightness-minus"></i> uil-brightness-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-brightness-plus"></i> uil-brightness-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-brightness"></i> uil-brightness
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bring-bottom"></i> uil-bring-bottom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bring-front"></i> uil-bring-front
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-brush-alt"></i> uil-brush-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bug"></i> uil-bug
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-building"></i> uil-building
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bullseye"></i> uil-bullseye
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bus-alt"></i> uil-bus-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bus-school"></i> uil-bus-school
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-bus"></i> uil-bus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calculator-alt"></i> uil-calculator-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calculator"></i> uil-calculator
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calendar-alt"></i> uil-calendar-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calendar-slash"></i> uil-calendar-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calender"></i> uil-calender
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-calling"></i> uil-calling
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-camera-change"></i> uil-camera-change
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-camera-plus"></i> uil-camera-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-camera-slash"></i> uil-camera-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-camera"></i> uil-camera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cancel"></i> uil-cancel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-capsule"></i> uil-capsule
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-capture"></i> uil-capture
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-car-sideview"></i> uil-car-sideview
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-car-slash"></i> uil-car-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-car-wash"></i> uil-car-wash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-car"></i> uil-car
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-card-atm"></i> uil-card-atm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-caret-right"></i> uil-caret-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cell"></i> uil-cell
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-celsius"></i> uil-celsius
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-bar-alt"></i> uil-chart-bar-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-bar"></i> uil-chart-bar
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-down"></i> uil-chart-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-growth-alt"></i> uil-chart-growth-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-growth"></i> uil-chart-growth
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-line"></i> uil-chart-line
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-pie-alt"></i> uil-chart-pie-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart-pie"></i> uil-chart-pie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chart"></i> uil-chart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chat-bubble-user"></i> uil-chat-bubble-user
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chat-info"></i> uil-chat-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-chat"></i> uil-chat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-check-circle"></i> uil-check-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-check-square"></i> uil-check-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-check"></i> uil-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-circle-layer"></i> uil-circle-layer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-circle"></i> uil-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-circuit"></i> uil-circuit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clapper-board"></i> uil-clapper-board
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clipboard-alt"></i> uil-clipboard-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clipboard-blank"></i> uil-clipboard-blank
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clipboard-notes"></i> uil-clipboard-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clipboard"></i> uil-clipboard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-eight"></i> uil-clock-eight
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-five"></i> uil-clock-five
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-nine"></i> uil-clock-nine
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-seven"></i> uil-clock-seven
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-ten"></i> uil-clock-ten
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-three"></i> uil-clock-three
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock-two"></i> uil-clock-two
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-clock"></i> uil-clock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-closed-captioning"></i> uil-closed-captioning
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-block"></i> uil-cloud-block
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-bookmark"></i> uil-cloud-bookmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-check"></i> uil-cloud-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-computing"></i> uil-cloud-computing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-data-connection"></i>
                uil-cloud-data-connection
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-database-tree"></i> uil-cloud-database-tree
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-download"></i> uil-cloud-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-drizzle"></i> uil-cloud-drizzle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-exclamation"></i> uil-cloud-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-hail"></i> uil-cloud-hail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-heart"></i> uil-cloud-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-info"></i> uil-cloud-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-lock"></i> uil-cloud-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-meatball"></i> uil-cloud-meatball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-moon-hail"></i> uil-cloud-moon-hail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-moon-meatball"></i> uil-cloud-moon-meatball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-moon-rain"></i> uil-cloud-moon-rain
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-moon-showers"></i> uil-cloud-moon-showers
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-moon"></i> uil-cloud-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-question"></i> uil-cloud-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-rain-sun"></i> uil-cloud-rain-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-rain"></i> uil-cloud-rain
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-redo"></i> uil-cloud-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-share"></i> uil-cloud-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-shield"></i> uil-cloud-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-showers-alt"></i> uil-cloud-showers-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-showers-heavy"></i> uil-cloud-showers-heavy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-showers"></i> uil-cloud-showers
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-slash"></i> uil-cloud-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun-hail"></i> uil-cloud-sun-hail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun-meatball"></i> uil-cloud-sun-meatball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun-rain-alt"></i> uil-cloud-sun-rain-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun-rain"></i> uil-cloud-sun-rain
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun-tear"></i> uil-cloud-sun-tear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-sun"></i> uil-cloud-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-times"></i> uil-cloud-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-unlock"></i> uil-cloud-unlock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-upload"></i> uil-cloud-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-wifi"></i> uil-cloud-wifi
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cloud-wind"></i> uil-cloud-wind
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cog"></i> uil-cog
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-coins"></i> uil-coins
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-columns"></i> uil-columns
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-block"></i> uil-comment-alt-block
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-chart-lines"></i>
                uil-comment-alt-chart-lines
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-check"></i> uil-comment-alt-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-dots"></i> uil-comment-alt-dots
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-download"></i>
                uil-comment-alt-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-edit"></i> uil-comment-alt-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-exclamation"></i>
                uil-comment-alt-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-heart"></i> uil-comment-alt-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-image"></i> uil-comment-alt-image
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-info"></i> uil-comment-alt-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-lines"></i> uil-comment-alt-lines
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-lock"></i> uil-comment-alt-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-medical"></i> uil-comment-alt-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-message"></i> uil-comment-alt-message
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-notes"></i> uil-comment-alt-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-plus"></i> uil-comment-alt-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-question"></i>
                uil-comment-alt-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-redo"></i> uil-comment-alt-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-search"></i> uil-comment-alt-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-share"></i> uil-comment-alt-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-shield"></i> uil-comment-alt-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-slash"></i> uil-comment-alt-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-upload"></i> uil-comment-alt-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt-verify"></i> uil-comment-alt-verify
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-alt"></i> uil-comment-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-block"></i> uil-comment-block
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-chart-line"></i> uil-comment-chart-line
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-check"></i> uil-comment-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-dots"></i> uil-comment-dots
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-arrow-down"></i> uil-arrow-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-download"></i> uil-comment-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-edit"></i> uil-comment-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-exclamation"></i> uil-comment-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-heart"></i> uil-comment-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-image"></i> uil-comment-image
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-info-alt"></i> uil-comment-info-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-info"></i> uil-comment-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-lines"></i> uil-comment-lines
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-lock"></i> uil-comment-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-medical"></i> uil-comment-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-message"></i> uil-comment-message
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-notes"></i> uil-comment-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-plus"></i> uil-comment-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-question"></i> uil-comment-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-redo"></i> uil-comment-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-search"></i> uil-comment-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-share"></i> uil-comment-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-shield"></i> uil-comment-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-slash"></i> uil-comment-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-upload"></i> uil-comment-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment-verify"></i> uil-comment-verify
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comment"></i> uil-comment
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comments-alt"></i> uil-comments-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-comments"></i> uil-comments
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compact-disc"></i> uil-compact-disc
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compass"></i> uil-compass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-alt-left"></i> uil-compress-alt-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-alt"></i> uil-compress-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-arrows"></i> uil-compress-arrows
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-lines"></i> uil-compress-lines
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-point"></i> uil-compress-point
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress-v"></i> uil-compress-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compress"></i> uil-compress
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-confused"></i> uil-confused
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-constructor"></i> uil-constructor
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-copy-alt"></i> uil-copy-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-copy-landscape"></i> uil-copy-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-copy"></i> uil-copy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-copyright"></i> uil-copyright
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-down-left"></i> uil-corner-down-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-down-right-alt"></i>
                uil-corner-down-right-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-down-right"></i> uil-corner-down-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-left-down"></i> uil-corner-left-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-right-down"></i> uil-corner-right-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-up-left-alt"></i> uil-corner-up-left-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-up-left"></i> uil-corner-up-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-up-right-alt"></i> uil-corner-up-right-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-corner-up-right"></i> uil-corner-up-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-creative-commons-pd"></i> uil-creative-commons-pd
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crockery"></i> uil-crockery
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crop-alt-rotate-left"></i>
                uil-crop-alt-rotate-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crop-alt-rotate-right"></i>
                uil-crop-alt-rotate-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crop-alt"></i> uil-crop-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crosshair-alt"></i> uil-crosshair-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crosshair"></i> uil-crosshair
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-crosshairs"></i> uil-crosshairs
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-cube"></i> uil-cube
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dashboard"></i> uil-dashboard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-data-sharing"></i> uil-data-sharing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-database-alt"></i> uil-database-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compass"></i> uil-compass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-database"></i> uil-database
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desert"></i> uil-desert
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desktop-alt-slash"></i> uil-desktop-alt-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desktop-alt"></i> uil-desktop-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desktop-cloud-alt"></i> uil-desktop-cloud-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desktop-slash"></i> uil-desktop-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-desktop"></i> uil-desktop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dialpad-alt"></i> uil-dialpad-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dialpad"></i> uil-dialpad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-diamond"></i> uil-diamond
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-diary-alt"></i> uil-diary-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-diary"></i> uil-diary
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-five"></i> uil-dice-five
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-four"></i> uil-dice-four
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-one"></i> uil-dice-one
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-six"></i> uil-dice-six
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-three"></i> uil-dice-three
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dice-two"></i> uil-dice-two
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-direction"></i> uil-direction
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-directions"></i> uil-directions
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dizzy-meh"></i> uil-dizzy-meh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dna"></i> uil-dna
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-document-layout-left"></i>
                uil-document-layout-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-document-layout-right"></i>
                uil-document-layout-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dollar-alt"></i> uil-dollar-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dollar-sign-alt"></i> uil-dollar-sign-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dollar-sign"></i> uil-dollar-sign
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-download-alt"></i> uil-download-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dribbble"></i> uil-dribbble
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dropbox"></i> uil-dropbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-dumbbell"></i> uil-dumbbell
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ear"></i> uil-ear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-edit-alt"></i> uil-edit-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-edit"></i> uil-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ellipsis-h"></i> uil-ellipsis-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ellipsis-v"></i> uil-ellipsis-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-emoji"></i> uil-emoji
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-enter"></i> uil-enter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-add"></i> uil-envelope-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-alt"></i> uil-envelope-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-block"></i> uil-envelope-block
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-bookmark"></i> uil-envelope-bookmark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-check"></i> uil-envelope-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-download-alt"></i>
                uil-envelope-download-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-download"></i> uil-envelope-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-edit"></i> uil-envelope-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-exclamation"></i>
                uil-envelope-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-heart"></i> uil-envelope-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-info"></i> uil-envelope-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-lock"></i> uil-envelope-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-minus"></i> uil-envelope-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-open"></i> uil-envelope-open
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-question"></i> uil-envelope-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-receive"></i> uil-envelope-receive
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-redo"></i> uil-envelope-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-search"></i> uil-envelope-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-send"></i> uil-envelope-send
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-share"></i> uil-envelope-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-shield"></i> uil-envelope-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-star"></i> uil-envelope-star
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-times"></i> uil-envelope-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-upload-alt"></i> uil-envelope-upload-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope-upload"></i> uil-envelope-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelope"></i> uil-envelope
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-envelopes"></i> uil-envelopes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-equal-circle"></i> uil-equal-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-euro-circle"></i> uil-euro-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-euro"></i> uil-euro
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exchange-alt"></i> uil-exchange-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exchange"></i> uil-exchange
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exclamation-circle"></i> uil-exclamation-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exclamation-octagon"></i> uil-exclamation-octagon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exclamation-triangle"></i>
                uil-exclamation-triangle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exclude"></i> uil-exclude
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-alt"></i> uil-expand-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-arrows-alt"></i> uil-expand-arrows-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-arrows"></i> uil-expand-arrows
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-from-corner"></i> uil-expand-from-corner
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-left"></i> uil-expand-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-expand-right"></i> uil-expand-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-export"></i> uil-export
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exposure-alt"></i> uil-exposure-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-exposure-increase"></i> uil-exposure-increase
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-external-link-alt"></i> uil-external-link-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-eye-slash"></i> uil-eye-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-eye"></i> uil-eye
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-facebook-f"></i> uil-facebook-f
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-facebook-messenger-alt"></i>
                uil-facebook-messenger-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-facebook-messenger"></i> uil-facebook-messenger
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-facebook"></i> uil-facebook
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-fahrenheit"></i> uil-fahrenheit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-fast-mail-alt"></i> uil-fast-mail-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-fast-mail"></i> uil-fast-mail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-favorite"></i> uil-favorite
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-feedback"></i> uil-feedback
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-alt"></i> uil-file-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-blank"></i> uil-file-blank
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-block-alt"></i> uil-file-block-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-bookmark-alt"></i> uil-file-bookmark-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-check-alt"></i> uil-file-check-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-check"></i> uil-file-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-contract-dollar"></i>
                uil-file-contract-dollar
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-copy-alt"></i> uil-file-copy-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-download-alt"></i> uil-file-download-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-download"></i> uil-file-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-edit-alt"></i> uil-file-edit-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-exclamation-alt"></i>
                uil-file-exclamation-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-exclamation"></i> uil-file-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-heart"></i> uil-file-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-info-alt"></i> uil-file-info-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-landscape-alt"></i> uil-file-landscape-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-landscape"></i> uil-file-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-lanscape-slash"></i> uil-file-lanscape-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-lock-alt"></i> uil-file-lock-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-medical-alt"></i> uil-file-medical-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-medical"></i> uil-file-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-minus-alt"></i> uil-file-minus-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-minus"></i> uil-file-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-network"></i> uil-file-network
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-plus-alt"></i> uil-file-plus-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-plus"></i> uil-file-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-question-alt"></i> uil-file-question-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-question"></i> uil-file-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-redo-alt"></i> uil-file-redo-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-search-alt"></i> uil-file-search-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-share-alt"></i> uil-file-share-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-shield-alt"></i> uil-file-shield-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-slash"></i> uil-file-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-times-alt"></i> uil-file-times-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-times"></i> uil-file-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-upload-alt"></i> uil-file-upload-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file-upload"></i> uil-file-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-file"></i> uil-file
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-files-landscapes-alt"></i>
                uil-files-landscapes-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-files-landscapes"></i> uil-files-landscapes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-film"></i> uil-film
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-filter-slash"></i> uil-filter-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-filter"></i> uil-filter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-fire"></i> uil-fire
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flask-potion"></i> uil-flask-potion
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flask"></i> uil-flask
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flip-h-alt"></i> uil-flip-h-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flip-h"></i> uil-flip-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flip-v-alt"></i> uil-flip-v-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flip-v"></i> uil-flip-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-flower"></i> uil-flower
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-focus-add"></i> uil-focus-add
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-focus-target"></i> uil-focus-target
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-focus"></i> uil-focus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-check"></i> uil-folder-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-download"></i> uil-folder-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-exclamation"></i> uil-folder-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-heart"></i> uil-folder-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-info"></i> uil-folder-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-lock"></i> uil-folder-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-medical"></i> uil-folder-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-minus"></i> uil-folder-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-network"></i> uil-folder-network
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-plus"></i> uil-folder-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-question"></i> uil-folder-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-slash"></i> uil-folder-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-times"></i> uil-folder-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder-upload"></i> uil-folder-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-folder"></i> uil-folder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-football-american"></i> uil-football-american
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-football-ball"></i> uil-football-ball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-football"></i> uil-football
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-forecastcloud-moon-tear"></i>
                uil-forecastcloud-moon-tear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-forwaded-call"></i> uil-forwaded-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-forward"></i> uil-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-frown"></i> uil-frown
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-game-structure"></i> uil-game-structure
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-gift"></i> uil-gift
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-github-alt"></i> uil-github-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-github"></i> uil-github
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-glass-martini-alt-slash"></i>
                uil-glass-martini-alt-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-glass-martini-alt"></i> uil-glass-martini-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-glass-martini"></i> uil-glass-martini
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-compass"></i> uil-compass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-glass-tea"></i> uil-glass-tea
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-glass"></i> uil-glass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-globe"></i> uil-globe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-gold"></i> uil-gold
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google-drive-alt"></i> uil-google-drive-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google-drive"></i> uil-google-drive
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google-hangouts-alt"></i> uil-google-hangouts-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google-hangouts"></i> uil-google-hangouts
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google-play"></i> uil-google-play
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-google"></i> uil-google
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-graph-bar"></i> uil-graph-bar
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-grid"></i> uil-grid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-grids"></i> uil-grids
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-grin-tongue-wink-alt"></i>
                uil-grin-tongue-wink-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-grin-tongue-wink"></i> uil-grin-tongue-wink
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-grin"></i> uil-grin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-hdd"></i> uil-hdd
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-headphones-alt"></i> uil-headphones-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-headphones"></i> uil-headphones
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heart-alt"></i> uil-heart-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heart-medical"></i> uil-heart-medical
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heart-rate"></i> uil-heart-rate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heart-sign"></i> uil-heart-sign
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heart"></i> uil-heart
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-heartbeat"></i> uil-heartbeat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-history-alt"></i> uil-history-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-history"></i> uil-history
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-home-alt"></i> uil-home-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-home"></i> uil-home
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-align-center"></i>
                uil-horizontal-align-center
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-align-left"></i>
                uil-horizontal-align-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-align-right"></i>
                uil-horizontal-align-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-distribution-center"></i>
                uil-horizontal-distribution-center
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-distribution-left"></i>
                uil-horizontal-distribution-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-horizontal-distribution-right"></i>
                uil-horizontal-distribution-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-hourglass"></i> uil-hourglass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-html3-alt"></i> uil-html3-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="u uil-html3"></i> u uil-html3
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-hunting"></i> uil-hunting
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-alt-slash"></i> uil-image-alt-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-block"></i> uil-image-block
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-broken"></i> uil-image-broken
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-check"></i> uil-image-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-download"></i> uil-image-download
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-edit"></i> uil-image-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-lock"></i> uil-image-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-minus"></i> uil-image-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-plus"></i> uil-image-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-question"></i> uil-image-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-redo"></i> uil-image-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-resize-landscape"></i>
                uil-image-resize-landscape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-resize-square"></i> uil-image-resize-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-search"></i> uil-image-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-share"></i> uil-image-share
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-shield"></i> uil-image-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-slash"></i> uil-image-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-times"></i> uil-image-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-upload"></i> uil-image-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image-v"></i> uil-image-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-image"></i> uil-image
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-images"></i> uil-images
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-import"></i> uil-import
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-incoming-call"></i> uil-incoming-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-info-circle"></i> uil-info-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-instagram-alt"></i> uil-instagram-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-instagram"></i> uil-instagram
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-intercom-alt"></i> uil-intercom-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-intercom"></i> uil-intercom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-invoice"></i> uil-invoice
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-italic"></i> uil-italic
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-jackhammer"></i> uil-jackhammer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-java-script"></i> uil-java-script
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-kayak"></i> uil-kayak
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-key-skeleton-alt"></i> uil-key-skeleton-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-key-skeleton"></i> uil-key-skeleton
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyboard-alt"></i> uil-keyboard-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyboard-hide"></i> uil-keyboard-hide
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyboard-show"></i> uil-keyboard-show
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyboard"></i> uil-keyboard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyhole-circle"></i> uil-keyhole-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyhole-square-full"></i> uil-keyhole-square-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-keyhole-square"></i> uil-keyhole-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-kid"></i> uil-kid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-label-alt"></i> uil-label-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-label"></i> uil-label
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lamp"></i> uil-lamp
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-laptop-cloud"></i> uil-laptop-cloud
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-laptop"></i> uil-laptop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-laughing"></i> uil-laughing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-layer-group-slash"></i> uil-layer-group-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-layer-group"></i> uil-layer-group
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-layers-alt"></i> uil-layers-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-layers-slash"></i> uil-layers-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-layers"></i> uil-layers
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-left-arrow-from-left"></i>
                uil-left-arrow-from-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-left-arrow-to-left"></i> uil-left-arrow-to-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-left-indent-alt"></i> uil-left-indent-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-left-indent"></i> uil-left-indent
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-left-to-right-text-direction"></i>
                uil-left-to-right-text-direction
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-life-ring"></i> uil-life-ring
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lightbulb-alt"></i> uil-lightbulb-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lightbulb"></i> uil-lightbulb
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-line-alt"></i> uil-line-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-line-spacing"></i> uil-line-spacing
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-line"></i> uil-line
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-link-alt"></i> uil-link-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-link-broken"></i> uil-link-broken
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-link-h"></i> uil-link-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-link"></i> uil-link
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-linkedin-alt"></i> uil-linkedin-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-linkedin"></i> uil-linkedin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-list-ui-alt"></i> uil-list-ui-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-list-ul"></i> uil-list-ul
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-location-arrow-alt"></i> uil-location-arrow-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-location-arrow"></i> uil-location-arrow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-location-pin-alt"></i> uil-location-pin-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-location-point"></i> uil-location-point
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lock-access"></i> uil-lock-access
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lock-alt"></i> uil-lock-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lock-open-alt"></i> uil-lock-open-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lock-slash"></i> uil-lock-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-lock"></i> uil-lock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mailbox-alt"></i> uil-mailbox-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mailbox"></i> uil-mailbox
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-alt"></i> uil-map-marker-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-edit"></i> uil-map-marker-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-info"></i> uil-map-marker-info
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-minus"></i> uil-map-marker-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-plus"></i> uil-map-marker-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-question"></i> uil-map-marker-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-shield"></i> uil-map-marker-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker-slash"></i> uil-map-marker-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-marker"></i> uil-map-marker
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-pin-alt"></i> uil-map-pin-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map-pin"></i> uil-map-pin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-map"></i> uil-map
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mars"></i> uil-mars
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-master-card"></i> uil-master-card
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-maximize-left"></i> uil-maximize-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medal"></i> uil-medal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medical-drip"></i> uil-medical-drip
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medical-square-full"></i> uil-medical-square-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medical-square"></i> uil-medical-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medium-m"></i> uil-medium-m
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-medkit"></i> uil-medkit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-meeting-board"></i> uil-meeting-board
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-megaphone"></i> uil-megaphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-meh-alt"></i> uil-meh-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-meh-closed-eye"></i> uil-meh-closed-eye
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-meh"></i> uil-meh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-message"></i> uil-message
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-metro"></i> uil-metro
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-microphone-slash"></i> uil-microphone-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-microphone"></i> uil-microphone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-minus-circle"></i> uil-minus-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-minus-path"></i> uil-minus-path
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-minus-square-full"></i> uil-minus-square-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-minus-square"></i> uil-minus-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-minus"></i> uil-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-java-script"></i> uil-java-script
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-missed-call"></i> uil-missed-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mobile-android-alt"></i> uil-mobile-android-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mobile-android"></i> uil-mobile-android
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mobile-vibrate"></i> uil-mobile-vibrate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-modem"></i> uil-modem
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-bill-stack"></i> uil-money-bill-stack
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-bill"></i> uil-money-bill
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-insert"></i> uil-money-insert
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-stack"></i> uil-money-stack
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-withdraw"></i> uil-money-withdraw
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-money-withdrawal"></i> uil-money-withdrawal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-moneybag-alt"></i> uil-moneybag-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-moneybag"></i> uil-moneybag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-monitor-heart-rate"></i> uil-monitor-heart-rate
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-monitor"></i> uil-monitor
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-moon-eclipse"></i> uil-moon-eclipse
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-moon"></i> uil-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-moonset"></i> uil-moonset
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mountains-sun"></i> uil-mountains-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mountains"></i> uil-mountains
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mouse-alt"></i> uil-mouse-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-mouse"></i> uil-mouse
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-music-note"></i> uil-music-note
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-music-tune-slash"></i> uil-music-tune-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-music"></i> uil-music
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-n-a"></i> uil-n-a
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-navigator"></i> uil-navigator
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-nerd"></i> uil-nerd
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-newspaper"></i> uil-newspaper
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ninja"></i> uil-ninja
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-no-entry"></i> uil-no-entry
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-notebooks"></i> uil-notebooks
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-notes"></i> uil-notes
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-object-group"></i> uil-object-group
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-object-ungroup"></i> uil-object-ungroup
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-octagon"></i> uil-octagon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-opera-alt"></i> uil-opera-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-opera"></i> uil-opera
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-outgoing-call"></i> uil-outgoing-call
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-package"></i> uil-package
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-padlock"></i> uil-padlock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-paint-tool"></i> uil-paint-tool
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-palette"></i> uil-palette
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-panorama-h-alt"></i> uil-panorama-h-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-panorama-h"></i> uil-panorama-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-panorama-v"></i> uil-panorama-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-paperclip"></i> uil-paperclip
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-paragraph"></i> uil-paragraph
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-parcel"></i> uil-parcel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-parking-square"></i> uil-parking-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pathfinder-unite"></i> uil-pathfinder-unite
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pathfinder"></i> uil-pathfinder
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pause-circle"></i> uil-pause-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pause"></i> uil-pause
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-paypal"></i> uil-paypal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pen"></i> uil-pen
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pentagon"></i> uil-pentagon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-percentage"></i> uil-percentage
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone-alt"></i> uil-phone-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone-pause"></i> uil-phone-pause
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone-slash"></i> uil-phone-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone-times"></i> uil-phone-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone-volume"></i> uil-phone-volume
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-phone"></i> uil-phone
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-picture"></i> uil-picture
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plane-arrival"></i> uil-plane-arrival
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plane-departure"></i> uil-plane-departure
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plane-fly"></i> uil-plane-fly
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plane"></i> uil-plane
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-play-circle"></i> uil-play-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-play"></i> uil-play
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plug"></i> uil-plug
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plus-circle"></i> uil-plus-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plus-square"></i> uil-plus-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-plus"></i> uil-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-podium"></i> uil-podium
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-polygon"></i> uil-polygon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-post-stamp"></i> uil-post-stamp
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-postcard"></i> uil-postcard
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pound-circle"></i> uil-pound-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pound"></i> uil-pound
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-power"></i> uil-power
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-prescription-bottle"></i> uil-prescription-bottle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-check"></i> uil-presentation-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-edit"></i> uil-presentation-edit
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-line"></i> uil-presentation-line
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-lines-alt"></i>
                uil-presentation-lines-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-minus"></i> uil-presentation-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-play"></i> uil-presentation-play
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-plus"></i> uil-presentation-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation-times"></i> uil-presentation-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-presentation"></i> uil-presentation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-previous"></i> uil-previous
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pricetag-alt"></i> uil-pricetag-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-print-slash"></i> uil-print-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-print"></i> uil-print
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-process"></i> uil-process
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-processor"></i> uil-processor
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-pump"></i> uil-pump
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-puzzle-piece"></i> uil-puzzle-piece
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-question-circle"></i> uil-question-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rainbow"></i> uil-rainbow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-raindrops-alt"></i> uil-raindrops-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-raindrops"></i> uil-raindrops
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-react"></i> uil-react
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-receipt-alt"></i> uil-receipt-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-receipt"></i> uil-receipt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-record-audio"></i> uil-record-audio
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-reddit-alien-alt"></i> uil-reddit-alien-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-redo"></i> uil-redo
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-refresh"></i> uil-refresh
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-registered"></i> uil-registered
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-repeat"></i> uil-repeat
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-restaurant"></i> uil-restaurant
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-right-indent-alt"></i> uil-right-indent-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-right-to-left-text-direction"></i>
                uil-right-to-left-text-direction
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-robot"></i> uil-robot
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rope-way"></i> uil-rope-way
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rotate-360"></i> uil-rotate-360
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rss-alt"></i> uil-rss-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rss-interface"></i> uil-rss-interface
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-rss"></i> uil-rss
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ruler-combined"></i> uil-ruler-combined
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ruler"></i> uil-ruler
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sad-cry"></i> uil-sad-cry
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sad-crying"></i> uil-sad-crying
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sad-dizzy"></i> uil-sad-dizzy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sad"></i> uil-sad
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-scaling-left"></i> uil-scaling-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-scaling-right"></i> uil-scaling-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-scenery"></i> uil-scenery
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-schedule"></i> uil-schedule
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-scroll-h"></i> uil-scroll-h
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-scroll"></i> uil-scroll
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-search-alt"></i> uil-search-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-search-minus"></i> uil-search-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-search-plus"></i> uil-search-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-search"></i> uil-search
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-selfie"></i> uil-selfie
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-server-alt"></i> uil-server-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-server-connection"></i> uil-server-connection
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-server-network-alt"></i> uil-server-network-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-server-network"></i> uil-server-network
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-server"></i> uil-server
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-servicemark"></i> uil-servicemark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-share-alt"></i> uil-share-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shield-check"></i> uil-shield-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shield-exclamation"></i> uil-shield-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shield-question"></i> uil-shield-question
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shield-slash"></i> uil-shield-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shield"></i> uil-shield
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ship"></i> uil-ship
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shop"></i> uil-shop
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shopping-basket"></i> uil-shopping-basket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shopping-cart-alt"></i> uil-shopping-cart-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shovel"></i> uil-shovel
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shrink"></i> uil-shrink
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shuffle"></i> uil-shuffle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shutter-alt"></i> uil-shutter-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-shutter"></i> uil-shutter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sick"></i> uil-sick
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sigma"></i> uil-sigma
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sign-alt"></i> uil-sign-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sign-in-alt"></i> uil-sign-in-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sign-left"></i> uil-sign-left
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sign-out-alt"></i> uil-sign-out-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sign-right"></i> uil-sign-right
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-signal-alt-3"></i> uil-signal-alt-3
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-signal-alt"></i> uil-signal-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-signal"></i> uil-signal
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-silence"></i> uil-silence
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-silent-squint"></i> uil-silent-squint
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sim-card"></i> uil-sim-card
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sitemap"></i> uil-sitemap
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-skip-forward-alt"></i> uil-skip-forward-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-skip-forward-circle"></i> uil-skip-forward-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-skip-forward"></i> uil-skip-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-skype-alt"></i> uil-skype-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-skype"></i> uil-skype
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-slack-alt"></i> uil-slack-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-slack"></i> uil-slack
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sliders-v-alt"></i> uil-sliders-v-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sliders-v"></i> uil-sliders-v
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-beam"></i> uil-smile-beam
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-dizzy"></i> uil-smile-dizzy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-squint-wink-alt"></i>
                uil-smile-squint-wink-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-squint-wink"></i> uil-smile-squint-wink
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-wink-alt"></i> uil-smile-wink-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile-wink"></i> uil-smile-wink
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-smile"></i> uil-smile
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snapchat-alt"></i> uil-snapchat-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snapchat-ghost"></i> uil-snapchat-ghost
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snapchat-square"></i> uil-snapchat-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snow-flake"></i> uil-snow-flake
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snowflake-alt"></i> uil-snowflake-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-snowflake"></i> uil-snowflake
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sort-amount-down"></i> uil-sort-amount-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sort-amount-up"></i> uil-sort-amount-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sort"></i> uil-sort
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sorting"></i> uil-sorting
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-space-key"></i> uil-space-key
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-spade"></i> uil-spade
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sperms"></i> uil-sperms
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-spin"></i> uil-spin
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-square-full"></i> uil-square-full
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-square-shape"></i> uil-square-shape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-squint"></i> uil-squint
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-star-half-alt"></i> uil-star-half-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-star"></i> uil-star
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-step-backward-alt"></i> uil-step-backward-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-step-backward-circle"></i>
                uil-step-backward-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-step-backward"></i> uil-step-backward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-step-forward"></i> uil-step-forward
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-stop-circle"></i> uil-stop-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-stopwatch-slash"></i> uil-stopwatch-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-stopwatch"></i> uil-stopwatch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-store-alt"></i> uil-store-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-store"></i> uil-store
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-streering"></i> uil-streering
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-stretcher"></i> uil-stretcher
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-subject"></i> uil-subject
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-subway-alt"></i> uil-subway-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-subway"></i> uil-subway
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-suitcase-alt"></i> uil-suitcase-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-suitcase"></i> uil-suitcase
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sun"></i> uil-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sunset"></i> uil-sunset
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-surprise"></i> uil-surprise
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-swatchbook"></i> uil-swatchbook
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-swiggy"></i> uil-swiggy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-swimmer"></i> uil-swimmer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sync-exclamation"></i> uil-sync-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sync-slash"></i> uil-sync-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-sync"></i> uil-sync
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-syringe"></i> uil-syringe
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-table"></i> uil-table
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tablet"></i> uil-tablet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tablets"></i> uil-tablets
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tachometer-fast"></i> uil-tachometer-fast
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tag-alt"></i> uil-tag-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tag"></i> uil-tag
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tape"></i> uil-tape
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-taxi"></i> uil-taxi
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tear"></i> uil-tear
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-telegram-alt"></i> uil-telegram-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-telegram"></i> uil-telegram
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-telescope"></i> uil-telescope
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-empty"></i> uil-temperature-empty
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-half"></i> uil-temperature-half
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-minus"></i> uil-temperature-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-plus"></i> uil-temperature-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-quarter"></i> uil-temperature-quarter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature-three-quarter"></i>
                uil-temperature-three-quarter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-temperature"></i> uil-temperature
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-text-fields"></i> uil-text-fields
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-text-size"></i> uil-text-size
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-text-strike-through"></i> uil-text-strike-through
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-text"></i> uil-text
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-th-large"></i> uil-th-large
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-th-slash"></i> uil-th-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-th"></i> uil-th
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thermometer"></i> uil-thermometer
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thumbs-down"></i> uil-thumbs-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thumbs-up"></i> uil-thumbs-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thunderstorm-moon"></i> uil-thunderstorm-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thunderstorm-sun"></i> uil-thunderstorm-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-thunderstorm"></i> uil-thunderstorm
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-ticket"></i> uil-ticket
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-times-circle"></i> uil-times-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-times-square"></i> uil-times-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-times"></i> uil-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-toggle-off"></i> uil-toggle-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-toggle-on"></i> uil-toggle-on
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-top-arrow-from-top"></i> uil-top-arrow-from-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-top-arrow-to-top"></i> uil-top-arrow-to-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tornado"></i> uil-tornado
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trademark-circle"></i> uil-trademark-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trademark"></i> uil-trademark
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-traffic-barrier"></i> uil-traffic-barrier
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trash-alt"></i> uil-trash-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trash"></i> uil-trash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trees"></i> uil-trees
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-triangle"></i> uil-triangle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-trophy"></i> uil-trophy
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-truck-loading"></i> uil-truck-loading
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-truck"></i> uil-truck
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tumblr-alt"></i> uil-tumblr-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tumblr-square"></i> uil-tumblr-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tumblr"></i> uil-tumblr
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tv-retro-slash"></i> uil-tv-retro-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-tv-retro"></i> uil-tv-retro
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-twitter-alt"></i> uil-twitter-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-twitter"></i> uil-twitter
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-umbrella"></i> uil-umbrella
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-unamused"></i> uil-unamused
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-underline"></i> uil-underline
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-university"></i> uil-university
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-unlock-alt"></i> uil-unlock-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-unlock"></i> uil-unlock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-upload-alt"></i> uil-upload-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-upload"></i> uil-upload
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-usd-circle"></i> uil-usd-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-usd-square"></i> uil-usd-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-check"></i> uil-user-check
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-circle"></i> uil-user-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-exclamation"></i> uil-user-exclamation
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-minus"></i> uil-user-minus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-plus"></i> uil-user-plus
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-square"></i> uil-user-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user-times"></i> uil-user-times
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-user"></i> uil-user
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-users-alt"></i> uil-users-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-utensils-alt"></i> uil-utensils-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-utensils"></i> uil-utensils
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vector-square-alt"></i> uil-vector-square-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vector-square"></i> uil-vector-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-align-bottom"></i>
                uil-vertical-align-bottom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-align-center"></i>
                uil-vertical-align-center
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-align-top"></i> uil-vertical-align-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-distribute-bottom"></i>
                uil-vertical-distribute-bottom
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-distribution-center"></i>
                uil-vertical-distribution-center
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vertical-distribution-top"></i>
                uil-vertical-distribution-top
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-video-slash"></i> uil-video-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-video"></i> uil-video
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-visual-studio"></i> uil-visual-studio
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vk-alt"></i> uil-vk-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vk"></i> uil-vk
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-voicemail-rectangle"></i> uil-voicemail-rectangle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-voicemail"></i> uil-voicemail
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volleyball"></i> uil-volleyball
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volume-down"></i> uil-volume-down
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volume-mute"></i> uil-volume-mute
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volume-off"></i> uil-volume-off
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volume-up"></i> uil-volume-up
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-volume"></i> uil-volume
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vuejs-alt"></i> uil-vuejs-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-vuejs"></i> uil-vuejs
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wall"></i> uil-wall
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wallet"></i> uil-wallet
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-watch-alt"></i> uil-watch-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-square"></i> uil-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-watch"></i> uil-watch
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-water-drop-slash"></i> uil-water-drop-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-water-glass"></i> uil-water-glass
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-water"></i> uil-water
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-web-grid-alt"></i> uil-web-grid-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-web-grid"></i> uil-web-grid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-web-section-alt"></i> uil-web-section-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-web-section"></i> uil-web-section
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-webcam"></i> uil-webcam
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-weight"></i> uil-weight
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-whatsapp"></i> uil-whatsapp
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wheel-barrow"></i> uil-wheel-barrow
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wheelchair-alt"></i> uil-wheelchair-alt
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wheelchair"></i> uil-wheelchair
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wifi-router"></i> uil-wifi-router
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wifi-slash"></i> uil-wifi-slash
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wifi"></i> uil-wifi
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wind-moon"></i> uil-wind-moon
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wind-sun"></i> uil-wind-sun
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wind"></i> uil-wind
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-window-grid"></i> uil-window-grid
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-window-maximize"></i> uil-window-maximize
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-window-section"></i> uil-window-section
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-window"></i> uil-window
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-windsock"></i> uil-windsock
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wrap-text"></i> uil-wrap-text
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-wrench"></i> uuil-wrench
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-square"></i> uil-square
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-yen-circle"></i> uil-yen-circle
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-yen"></i> uil-yen
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-yin-yang"></i> uil-yin-yang
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-6">
                <i class="uil-youtube"></i> uil-youtube
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
